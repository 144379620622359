<template>
  <div v-if="clientDetail !== ''">
    <div class="white client--container">
      <div
        class="d-flex justify-space-between align-center py-5 px-5 border--bottom"
      >
        <h1 class="font-weight-black text-h6 primary--text">
          DETALLES DE CLIENTE
        </h1>
        <div class="d-flex justify-end">
          <v-btn class="mr-5" depressed color="primary" @click="openPayment"
            >REALIZAR PAGO</v-btn
          >
          <v-btn
            depressed
            outlined
            color="primary"
            @click="$emit('changeComponent', 'ClientsTable')"
            >REGRESAR</v-btn
          >
        </div>
      </div>
      <v-row class="pa-0 ma-0">
        <v-col cols="4" class="pt-5 border--bottom border--right">
          <div class="d-flex mb-8">
            <div class="pl-5 mr-5">
              <icon-client class="icon--client"></icon-client>
            </div>
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-0">Cliente</p>
              <p class="font-weight-black mb-0">DATOS PERSONALES</p>
            </div>
          </div>

          <div class="pl-5 mb-3">
            <p class="text-subtitle-2 font-weight-medium mb-0">
              No. de cliente
            </p>
            <p class="font-weight-black text-uppercase text-h6 mb-0">
              {{ clientDetail.id.slice(-5) }}
            </p>
          </div>
          <div class="pl-5 mb-3">
            <p class="text-subtitle-2 font-weight-medium mb-0">Nombre</p>
            <p class="font-weight-black text-uppercase text-h6 mb-0">
              {{ getName() }}
            </p>
          </div>
          <div class="pl-5 mb-3">
            <p class="text-subtitle-2 font-weight-medium mb-0">
              No. de teléfono
            </p>
            <p class="font-weight-black text-uppercase text-h6 mb-0">
              {{ clientDetail.movilNumber }}
            </p>
          </div>
          <div class="pl-5 mb-3">
            <p class="text-subtitle-2 font-weight-medium mb-0">Domicilio</p>
            <p class="font-weight-black text-uppercase text-h6 mb-0">
              {{ getAddress() }}
            </p>
          </div>
          <div class="pl-5 mb-3">
            <p class="text-subtitle-2 font-weight-medium mb-0">
              Fecha de registro
            </p>
            <p class="font-weight-black text-uppercase text-h6 mb-0">
              {{ getShortDate(clientDetail.registrationDate) }}
            </p>
          </div>
          <div class="pl-5 mb-3">
            <p class="text-subtitle-2 font-weight-medium mb-0">
              Internet hogar
            </p>
            <p
              class="font-weight-black text-uppercase text-h6 mb-0"
              :class="getColorStatus(clientDetail.internetHogarStatus)"
            >
              {{ clientDetail.internetHogarStatus }}
            </p>
          </div>
          <div v-if="clientDetail.cutoffDate" class="pl-5 mb-3">
            <p class="text-subtitle-2 font-weight-medium mb-0">
              Fecha de corte
            </p>
            <p class="font-weight-black text-uppercase text-h6 mb-0">
              {{ getShortDate(clientDetail.cutoffDate) }}
            </p>
          </div>
          <div v-if="clientDetail.tag" class="pl-5 mb-15">
            <p class="text-subtitle-2 font-weight-medium mb-0">
              Tag
            </p>
            <p
              class="font-weight-black text-uppercase text-h6 mb-0"
              v-html="formattedTag"
            ></p>
          </div>
        </v-col>
        <v-col cols="8" class="pt-5 pb-2 border--bottom border--right">
          <div class="d-flex mb-5">
            <div class="pl-3 mr-5">
              <icon-internet-home></icon-internet-home>
            </div>
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-0">Servicio</p>
              <p class="text-uppercase font-weight-black mb-0">
                HISTORIAL DE PAGOS
              </p>
            </div>
          </div>

          <div
            class="d-flex px-3 mb-3 pt-0 pb-0 text-center history-payments--header"
          >
            <div
              class="py-3 text-left content--30 primary--text text-body-2 font-weight-black"
            >
              Fecha de pago
            </div>
            <div
              class="py-3 text-left content--30 primary--text text-body-2 font-weight-black"
            >
              Periodo
            </div>
            <div
              class="py-3 content--10 primary--text text-body-2 font-weight-black"
            >
              Monto
            </div>
            <div
              class="py-3 content--10 primary--text text-body-2 font-weight-black"
            >
              Estatus
            </div>
            <div
              class="py-3 content--20 primary--text text-body-2 font-weight-black"
            >
              Acciones
            </div>
          </div>

          <div>
            <div
              class="d-flex align-center px-3 pt-3"
              v-for="(payment, index) in clientDetail.payments"
              :key="index"
            >
              <div class="text-caption content--30">
                {{ getShortDate(payment.datePayment).toUpperCase() }}
                {{ getTime(payment.datePayment).toUpperCase() }}
              </div>
              <div class="text-caption content--30">
                {{ payment.period }}
              </div>
              <div class="text-caption text-center content--10">
                {{ payment.mount | formatCurrency }}
              </div>
              <div class="text-caption text-center content--10">
                {{ payment.status }}
              </div>
              <div class="text-caption text-center content--20">
                <v-btn icon @click="print(index)">
                  <icon-bill class="icon--bill"></icon-bill>
                </v-btn>
                <v-btn @click="openEdit(payment)" color="primary" icon
                  ><v-icon> mdi-pencil-outline </v-icon></v-btn
                >
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <component
      v-if="dialog"
      :is="component"
      :isEdit="isEdit"
      :paymentData="paymentData"
      :dialog="dialog"
      :dialogConfirm="dialog"
      :body="body"
      @close="dialog = false"
      @confirm="dialog = false"
      @toPay="toPay"
      @updatePayment="updatePayment"
    ></component>
  </div>
</template>

<script>
// Domains
import Client from "../../domain/Clients";
import Purchase from "../../domain/Purchase";

// Mixins
import { FormatsMixin } from "../../mixins/Formats";

// Object
import PrintTicket from "../../helpers/PrintTicket";

export default {
  name: "ClientDetail",
  mixins: [FormatsMixin],
  mounted() {
    this.clientDetail = Object.assign({}, this.clientDetailOrigin);
  },
  components: {
    IconClient: () => import("../icons/IconClient"),
    IconInternetHome: () => import("../icons/IconInternetHome"),
    IconInternetMovil: () => import("../icons/IconInternetMovil"),
    IconBill: () => import("../icons/IconBill"),
    ModalConfirm: () => import("../base/ModalConfirm"),
    PaymentService: () => import("./payments/PaymentService"),
  },
  props: {
    clientDetailOrigin: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedTag() {
      return this.clientDetail.tag
        ? this.clientDetail.tag.replace(/\n/g, "<br>")
        : "";
    }
  },
  data: () => ({
    dialog: false,
    isEdit: false,
    paymentData: {},
    component: "PaymentService",
    planData: {
      idClient: "",
      idPlan: "",
    },
    body: {
      title: "PAGO DE SERVICIO",
      message: "Pago realizado correctamente",
    },
    plans: [],
    clientDetail: "",
    payDetail: {},
  }),
  methods: {
    getName() {
      return `${this.clientDetail.name} ${this.clientDetail.lastNamePather} ${this.clientDetail.lastNameMother}`;
    },
    getAddress() {
      return `${this.clientDetail.street} ${this.clientDetail.outdoorNumber}, ${this.clientDetail.suburb}, C.P. ${this.clientDetail.zip}, ${this.clientDetail.city}`;
    },
    openPayment() {
      this.isEdit = false;
      this.component = "PaymentService";
      this.dialog = true;
    },
    openEdit(payment) {
      this.paymentData = Object.assign({}, payment);
      this.isEdit = true;
      this.component = "PaymentService";
      this.dialog = true;
    },
    async toPay(payment) {
      try {
        // Pago
        payment.idClient = this.clientDetailOrigin.id;
        const pay = await Purchase.purchaseHomePlan(payment);
        console.log(pay);
        // Actualizar cliente
        await this.refreshView();
      } catch (error) {
        console.warn(error);
      }
    },
    async refreshView() {
      try {
        const response = await Client.findById(this.clientDetail.id);
        response.id = this.clientDetail.id;
        this.clientDetail = response;
        this.component = "ModalConfirm";
      } catch (error) {
        console.warn(error);
      }
    },
    print(index) {
      const payment = this.clientDetail.payments[index];
      const client = {
        id: this.clientDetail.id.slice(-5),
        name: this.getName(),
        phone: this.clientDetail.movilNumber,
        address: this.getAddress(),
      };

      PrintTicket.HomePlan(client, payment);
    },
    async updatePayment(payment) {
      try {
        await Purchase.purchaseUpdateHomePlan(payment);
        await this.refreshView();
      } catch (error) {
        console.warn(error);
      }
    },
    getColorStatus(status){
      console.log(status);
      if(status==='ACTIVADO') return 'green--text';
      if(status==='PAGO PENDIENTE') return 'orange--text';
      if(status==='CORTE DE SERVICIO') return 'red--text';
    }
  },
};
</script>

<style lang="scss" scoped>
$border-color: #d8d8d8;

.icon--bill {
  width: 1.1rem;
}

.icon--internet-movil {
  width: 38px;
}

.icon--client {
  height: 38px;
}

.client--container {
  border: 1px solid $border-color !important;
}

.border--bottom {
  border-bottom: 1px solid $border-color;
}

.border--right {
  border-right: 1px solid $border-color;
}

.content--50 {
  width: 50%;
}

.content--30 {
  width: 30%;
}

.content--20 {
  width: 20%;
}

.content--10 {
  width: 10%;
}

.payment--content {
  background-color: #f2f2f2;
}

.prueba::-webkit-scrollbar {
  -webkit-appearance: none;
}

.prueba::-webkit-scrollbar:vertical {
  width: 8px;
}

.prueba::-webkit-scrollbar-button:increment,
.prueba::-webkit-scrollbar-button {
  display: none;
}

.prueba::-webkit-scrollbar:horizontal {
  height: 10px;
}

.prueba::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 20px;
  // border: 2px solid #f1f2f3;
}

.prueba::-webkit-scrollbar-track {
  border-radius: 10px;
}

.history-payments--header {
  background: #f2f2f2;
  border-radius: 0.6rem;
}
</style>
